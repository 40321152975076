<template>
  <tr>
    <td>{{ employment.first_name }}</td>
    <td>{{ employment.last_name }}</td>
    <td>{{ departmentName }}</td>
    <td>{{ employeeCode }}</td>
    <td>{{ startDate }}</td>
    <td>{{ yearsOfService }}</td>
    <td>{{ employmentAllowance.allowanceType().name }}</td>
    <td>{{ allowanceYear }}</td>
    <td>
      {{ totalAnnualAllowance }}
    </td>
    <td v-if="hasToilOfferedForAtLeastOneAllowance">
      <span v-if="employmentAllowance.hasTimeOffInLieu()">
        {{ totalTimeOffInLieu }}
        {{ allowanceUnit | pluralize(totalTimeOffInLieu) }}
      </span>
      <span v-else>-</span>
    </td>
    <td>
      {{ totalCarryoverAllowance }}
    </td>
    <td>{{ isCarryoverLocked }}</td>
    <td>
      {{ totalExpiredCarryoverAllowance }}
    </td>
    <td>
      {{ totalAllowance }}
      {{ allowanceUnit | pluralize(totalAllowance) }}
    </td>
    <td>
      {{ accruedAllowance }}
      {{ allowanceUnit | pluralize(accruedAllowance) }}
    </td>
    <td>
      {{ bookedAllowance }}
      {{ allowanceUnit | pluralize(bookedAllowance) }}
    </td>
    <td>
      {{ takenAllowance }}
      {{ allowanceUnit | pluralize(takenAllowance) }}
    </td>
    <td>
      {{ remainingToBook }} {{ allowanceUnit | pluralize(remainingToBook) }}
    </td>
    <td>
      {{ remainingToTake }} {{ allowanceUnit | pluralize(remainingToTake) }}
    </td>
    <td>
      {{ accruedRemainingToBook }}
      {{ allowanceUnit | pluralize(accruedRemainingToBook) }}
    </td>
    <td>
      {{ accruedRemainingToTake }}
      {{ allowanceUnit | pluralize(accruedRemainingToTake) }}
    </td>
    <td>
      {{ totalCarriedAllowanceToNextYear }}
    </td>
  </tr>
</template>

<script>
import moment from 'moment-timezone'
import FormatDate from '@/mixins/FormatDate'
import pluralize from 'pluralize'
import EmploymentAllowance from '@/models/reporting/EmploymentAllowance'

export default {
  name: 'AllowanceDetailReportTableRow',

  mixins: [FormatDate],

  props: {
    employmentAllowance: {
      type: EmploymentAllowance,
      required: true,
    },
    hasToilOfferedForAtLeastOneAllowance: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    employment() {
      return this.employmentAllowance.employment()
    },

    departmentName() {
      if (!this.employment.department) return '-'

      return this.employment.department.name
    },

    employeeCode() {
      return this.employment.employee_code || '-'
    },

    startDate() {
      if (!this.employment.start_date) return '-'

      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        moment.utc(this.employment.start_date),
        'utc'
      )
    },

    yearsOfService() {
      if (!this.employment.years_of_service) return '-'

      return this.employment.years_of_service
    },

    allowanceYear() {
      return (
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
          moment.utc(this.employmentAllowance.calendar().start_date),
          'utc'
        ) +
        ' - ' +
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
          moment.utc(this.employmentAllowance.calendar().end_date),
          'utc'
        )
      )
    },

    totalTimeOffInLieu() {
      return this.employmentAllowance.totalTimeOffInLieu()
    },

    allowanceUnit() {
      return this.employmentAllowance.allowanceUnit().toLowerCase()
    },

    totalAllowance() {
      return this.employmentAllowance.totalAllowance()
    },

    isCarryoverLocked() {
      return this.employmentAllowance.isCarryoverLocked() ? 'Yes' : 'No'
    },

    totalAnnualAllowance() {
      const annualAllowance = this.employmentAllowance.annualAllowance()

      if (annualAllowance === 0) return '-'

      return (
        annualAllowance + ' ' + pluralize(this.allowanceUnit, annualAllowance)
      )
    },

    totalCarryoverAllowance() {
      const carryoverAllowance = this.employmentAllowance.carryoverAllowance()

      if (carryoverAllowance === 0) return '-'

      return (
        carryoverAllowance +
        ' ' +
        pluralize(this.allowanceUnit, carryoverAllowance)
      )
    },

    totalExpiredCarryoverAllowance() {
      const totalExpiredCarryoverAllowance = this.employmentAllowance.expiredCarryoverAllowance()

      if (totalExpiredCarryoverAllowance == null) return '-'

      return (
        totalExpiredCarryoverAllowance +
        ' ' +
        pluralize(this.allowanceUnit, totalExpiredCarryoverAllowance)
      )
    },

    totalCarriedAllowanceToNextYear() {
      const carriedAllowanceToNextYear = this.employmentAllowance.carriedAllowanceToNextYear()

      if (carriedAllowanceToNextYear === 0) return '-'

      return (
        carriedAllowanceToNextYear +
        ' ' +
        pluralize(this.allowanceUnit, carriedAllowanceToNextYear)
      )
    },

    bookedAllowance() {
      return this.employmentAllowance.bookedAllowance()
    },

    takenAllowance() {
      return this.employmentAllowance.takenAllowance()
    },

    accruedAllowance() {
      return this.employmentAllowance.accruedAllowance()
    },

    remainingToTake() {
      return this.employmentAllowance.remainingToTake()
    },

    remainingToBook() {
      return this.employmentAllowance.remainingToBook()
    },

    accruedRemainingToBook() {
      return this.employmentAllowance.accruedRemainingToBook()
    },

    accruedRemainingToTake() {
      return this.employmentAllowance.accruedRemainingToTake()
    },
  },
}
</script>
